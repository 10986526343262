import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from '@react-google-maps/api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import pinCluster from '../../icons/pin_cluster.svg';
import pinMultiple from '../../icons/pin_multiple.svg';
import pinMultipleHover from '../../icons/pin_multiple_hover.svg';
import pinSingle from '../../icons/pin_single.svg';
import pinSingleHover from '../../icons/pin_single_hover.svg';
import { OpportunityHover } from './OpportunityHover';
import { scalePoint, scaleSize } from './helpers';
import mapStyles from './mapStyles.json';
import type { LocationWithLabel } from '../../types';

const defaultMapCenter = {
  lat: 36,
  lng: -96,
};
const defaultZoom = 3;

export interface MapProps {
  locations: LocationWithLabel[];
  onBoundsChange: (bounds: google.maps.LatLngBounds) => void;
  onHoverChange: (hoveredMarker: LocationWithLabel | null) => void;
  onInteraction: () => void;
  className?: string;
  center?: google.maps.LatLng;
  zipCode?: string;
}

export const Map: React.FC<MapProps> = ({
  locations: markers,
  onBoundsChange,
  onHoverChange,
  onInteraction,
  className,
  center,
  zipCode,
}) => {
  const mapRef = useRef<google.maps.Map>();
  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const pinSingleInfo = useMemo(
    () => ({
      hoverOffset: new google.maps.Size(0, -16),
      anchor: new google.maps.Point(16, 16),
      size: new google.maps.Size(32, 32),
      url: pinSingle,
      hoverUrl: pinSingleHover,
    }),
    [],
  );

  const pinMultipleInfo = useMemo(
    () => ({
      hoverOffset: new google.maps.Size(16, -32),
      anchor: new google.maps.Point(0, 32),
      size: new google.maps.Size(32, 32),
      url: pinMultiple,
      hoverUrl: pinMultipleHover,
    }),
    [],
  );

  const onMapIdle = useCallback(() => {
    const bounds = mapRef.current?.getBounds();
    if (bounds) {
      onBoundsChange(bounds);
    }
  }, [onBoundsChange]);

  useEffect(() => {
    if (!mapRef.current || !center) {
      return;
    }

    mapRef.current.setCenter(center);
    mapRef.current.setZoom(12);
  }, [center]);

  useEffect(() => {
    if (!zipCode) {
      return;
    }

    (async () => {
      const geocoder = new google.maps.Geocoder();
      const { results } = await geocoder.geocode({ address: zipCode });
      if (!mapRef.current) {
        return;
      }

      const result = results[0];
      if (result && result.types.includes('postal_code')) {
        mapRef.current.setCenter(result.geometry.location);
        mapRef.current.setZoom(12);
      } else {
        mapRef.current.setCenter(defaultMapCenter);
        mapRef.current.setZoom(defaultZoom);
      }
    })();
  }, [zipCode]);

  const [hoveredMarker, setHoveredMarker] = useState<LocationWithLabel | null>(null);
  const handleHoverChange = (hoveredMarker: LocationWithLabel | null) => {
    setHoveredMarker(hoveredMarker);
    onHoverChange(hoveredMarker);
  };
  const hoverIconScale = 1.25;
  const hoverOffset = useMemo(() => {
    if (!hoveredMarker) {
      return null;
    }

    const pinInfo = hoveredMarker.activities.length > 1 ? pinMultipleInfo : pinSingleInfo;
    return scaleSize(pinInfo.hoverOffset, hoverIconScale);
  }, [hoveredMarker]);

  // This snippet coupled with setting the `noClustererRedraw` prop fixes performance issues with clustered markers
  // Based on https://github.com/JustFly1984/react-google-maps-api/issues/2849#issuecomment-1214105977
  const repaintClusterRef = useRef<() => void>();
  useEffect(() => {
    repaintClusterRef.current?.();
  }, [markers]);

  const firstIdle = useRef(true);

  return (
    <GoogleMap
      mapContainerClassName={className}
      center={defaultMapCenter}
      zoom={defaultZoom}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT }, // TODO: Remove when using v3.60
        cameraControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
        styles: mapStyles,
      }}
      onLoad={onMapLoad}
      onIdle={() => {
        if (firstIdle.current) {
          firstIdle.current = false;
        } else {
          onInteraction();
        }
        onMapIdle();
      }}
    >
      <MarkerClusterer
        onLoad={({ repaint }) => {
          repaintClusterRef.current = repaint;
        }}
        options={{
          styles: [{ url: pinCluster, width: 48, height: 48, textColor: 'white' }],
        }}
        calculator={(markers) => ({
          text: markers.length.toString(),
          index: 1,
        })}
      >
        {(clusterer) => (
          <>
            {markers.map((marker) => {
              const isHovered = hoveredMarker?.id === marker.id;
              const pinScale = isHovered ? hoverIconScale : 1;
              const pinInfo = marker.activities.length > 1 ? pinMultipleInfo : pinSingleInfo;
              const url = isHovered ? pinInfo.hoverUrl : pinInfo.url;
              return (
                <Marker
                  key={marker.id}
                  position={{
                    lat: marker.latitude,
                    lng: marker.longitude,
                  }}
                  clusterer={clusterer}
                  noClustererRedraw
                  label={{ text: marker.mapLabel, color: isHovered ? '#383f43' : 'white' }}
                  icon={{
                    url,
                    anchor: scalePoint(pinInfo.anchor, pinScale),
                    scaledSize: scaleSize(pinInfo.size, pinScale),
                  }}
                  onClick={() => handleHoverChange(marker)}
                />
              );
            })}
          </>
        )}
      </MarkerClusterer>
      {hoveredMarker && (
        <InfoWindow
          options={{ pixelOffset: hoverOffset, minWidth: 250 }}
          position={{
            lat: hoveredMarker.latitude,
            lng: hoveredMarker.longitude,
          }}
          onCloseClick={() => handleHoverChange(null)}
        >
          <OpportunityHover location={hoveredMarker} />
        </InfoWindow>
      )}
    </GoogleMap>
  );
};
